<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <iframe
          src="https://vpedia.sfo3.cdn.digitaloceanspaces.com/Random/Vpedia%20Guide.pdf"
          width="100%" height="600px"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'GuidePage'
}
</script>
