export default {
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل دخول"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل خروج"])},
  "Username or Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم أو البريد الالكتروني"])},
  "This field is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل مطلوب"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
  "Password must be at least 8 characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور يجب أن تكون أكثر من 8 رموز"])},
  "Password must contain an uppercase letter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور يجب أن تحتوي على أحرف كبيرة"])},
  "Password must contain an lowercase letter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور يجب أن تحتوي على أحرف صغيرة"])},
  "Password must contain a digit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور يجب أن تحتوي على رقم"])},
  "Password must contain a special character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور يجب أن تحتوي على رمز خاص"])},
  "Wrong Credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات خاطئة"])},
  "Enter Email or Username and Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل البريد الالكتروني أو اسم المستخدم وكلمة المرور"])},
  "Something Went Wrong!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ ما!"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إغلاق"])},
  "Vpedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموسوعة المرئية"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
  "Films": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأفلام"])},
  "Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخصص"])},
  "About Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
  "We are the leading private media institution in the Arab world that specializes in educational documentary film series of various types, from cultural, historical and scientific. For this purpose, we employ a group of distinguished media professionals, with extensive competencies and experience, each in his field. Our organization has produced a wide range of educational visual materials that have won awards in Arab festivals.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن المؤسسة الإعلامية الخاصة الرائدة في العالم العربي التي تختص في سلسلات الأفلام الوثائقية التعليمية بأصنافها المختلفة، من ثقافية تاريخية وعلمية. ونوظف لهذا الغرض نخبة من الإعلاميين المتميزين، من أصحاب الكفاءات والخبرات الواسعة، كل في مجاله. وقد انتجت مؤسستنا مجموعة كبيرة من المواد المرئية التعليمية التي نالت الجوائز في مهرجانات عربية."])},
  "Watch More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شاهد المزيد"])},
  "Most Watched Series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أبرز سلسلات الأفلام"])},
  "Watch all series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شاهد جميع سلاسل الأفلام"])},
  "Insights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضاءات وإنجازات"])},
  "Categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تخصص"])},
  "Documentary Series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلسلة وثائقية"])},
  "Scientific Films": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيلم علمي"])},
  "Views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاهدة"])},
  "Send us a message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أرسل لنا رسالة"])},
  "Contact information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات التواصل"])},
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني"])},
  "Phone Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
  "Subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموضوع"])},
  "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة"])},
  "Name is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم المطلوب"])},
  "Email is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني مطلوب"])},
  "Email must be valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل بريد صحيح"])},
  "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أرسل"])},
  "Your message has been sent successfuly!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال الرسالة بنجاح!"])},
  "Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحتوى"])},
  "out of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من أصل"])},
  "Film": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيلم"])},
  "Enter more than 1 letter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل أكثر من حرف"])},
  "Page number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحة رقم"])},
  "Pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفحات"])},
  "You don't have access to this film": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لديك حق الوصول لهذا الفيلم"])},
  "Scientific Reviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدقق العلمي لمحتوى الفيلم"])},
  "Main Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخصصات الرئيسية للفيلم"])},
  "Sub Categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخصصات الفرعية للفيلم"])},
  "From": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من سلسلة أفلام"])},
  "Enter Film Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل عنوان الفيلم"])},
  "Choose...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر..."])},
  "Read More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اقرأ المزيد"])},
  "Our organization pays special attention to the selection of topics and the content of its visual materials, as each film, in all film series, goes through multiple stages of work, so that we can produce each educational documentary in its distinct form and content, in which it appears on the screen. We take great care in preparing the wording of the Arabic texts carefully, as we use that text in the film, so that the word matches the image. After the final text is prepared, the scientific materials are reviewed by specialist scholars, each in his field, to ensure absolute guarantee of every piece of information, whether small or large, in each of our films. Over the years and decades, the Foundation has also chosen the best quotes to comment on its scientific documentaries.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وتولي مؤسستنا عناية فائقة بإختيار مواضيع ومضامين موادها المرئية، حيث يمر كل فيلم، في جميع سلسلات الأفلام، بمراحل عمل متعددة، حتى يتسنى لنا إخراج كل فيلم وثائقي تعليمي بشكله ومضمونه النهائي المتميز، الذي يظهر فيه على الشاشة. إذ نعتني بدقة إعداد وصياغة النصوص بلغة عربية رصينة، يتبع ذلك مطابقة النص بالفلم، كي توافق الكلمة مع الصورة. وبعد إعداد وصياغة النصوص بشكلها النهائي، تتم مراجعة المواد العلمية من قبل علماء وأصحاب إختصاص، كلٌ في مجاله، بهدف ضمان دقة وصحة كل معلومة، صغيرة كانت أم كبيرة، في كل فيلمٍ من أفلامنا. كما إختارت المؤسسة، على مر السنين والعقود أفضل الأصوات الإعلامية للتعليق على أفلامها الوثائقية العلمية."])},
  "About our institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نبذة عن مؤسستنا"])},
  "In addition to the above, our institution’s specialization in presenting documentary films leads us to direct special attention to historical, scientific and cultural films. We also pay similar attention to films that deal with the conditions of the contemporary world and shed light on its various issues. We begin our media work from a clear vision, the goal of which is to serve Arab culture, Arab students, scholars, and researchers, by selecting the best visual materials that achieve this goal, and presenting them in a framework that does not contradict the spirit of Arab-Islamic culture. Our method of preparing films is distinctive, which leads to providing what suits the recipients, in terms of wording, content, knowledge, and scientific benefit.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وفضلاً عما سبق فان تخصصَ مؤسستنا في تقديم الأفلام الوثائقية يقودُنا إلى توجيهِ اهتمامٍ خاصٍ بالأفلام التاريخيةِ والعلمية والثقافية. كما نوجه اهتماماً مماثلاً للأفلام التي تُعنى بأحوالِ العالمِ المعاصر، وتسلطُ الضَوءَ على قضاياه المختلفة. وإننا ننطلقُ في عملِنا الإعلامي من رؤيةٍ واضحةٍ، هدفُها خدمةُ الثقافةِ العربيةِ، والطلاب والداسين والباحثين العرب، باختيارِ أفضلِ المواد المرئية، التي تحققُ هذا الهدف، وتقديِمها في إطارٍ لا يتجافى وروحِ الثقافة العربية الإسلامية. فأسلوبنا في إعداد الأفلام أسلوبٌ مميز، يقود إلى تقديم ما يناسب المتلقين، صياغةً ومضموناً ومعرفةً وفائدة علمية."])},
  "Our organization presents these films from two points of view, the first of which is that the Arab viewer deserves to see films rich in information, serious, and of an excellent standard, and this viewer also has the right to keep up with the latest developments in the scientific, political, and economic fields in order to remain knowledgeable and informed about a developing world. always.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وتنطلقُ مؤسستنا وهي تقدمُ هذه الأفلام من منطلقين اثنين، أولهما أن المشاهدَ العربي يستحقُ أن يطلعَ على أفلام غنيةٍ بالمعلومات، جادةٍ، وذاتِ مستوىٍ ممتاز، كما أن لهذا المشاهد  الحقُ في مواكبةِ آخرِ التطوراتِ في المجالاتِ العلمية والسياسيةِ والاقتصادية كي يبقى على معرفةٍ وإطلاع على عالم يتطور على الدوام."])},
  "The second starting point in the philosophy of our organization is the keenness to enrich the Arab-Islamic personality and consolidate it in the souls of young people, and our way to do this is to provide these educational visual materials through which we present facts and information in an objective and sober manner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["والمنطلقُ الثاني في فلسفةِ مؤسستنا، هو الحرصُ على إغناءِ الشخصيةِ العربيةِ الإسلامية وترسيخِها في نفوسِ الناشئة، ووسيلتُنا إلى ذلك هي تقديمُ هذه موادٍ مرئية تعليمية نقومُ من خلالهِا بعرضِ الحقائقِ والمعلوماتِ بطريقةٍ موضوعيةٍ ورصينة"])},
  "as the benefit of watching one of our films is equivalent to reading an entire book.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ففائدة مشاهدة فلمٍ من أفلامنا تعادل قراءة كتاب بأكمله"])},
  "Through our diligent work, we seek to provide the best educational documentaries, in a way that expands perceptions, develops personality and stimulates productive work.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ونحن نسعى من خلال عملِنا الدؤوب إلى تقديمِ أفضلِ الأفلام  الوثائقية التعليمية، بما يوسعُ المداركَ، ويُنمّي الشخصيةْ ويحفزُ على العملِ المنتج."])},
  "Our institution philosophy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلسفة مؤسستنا"])},
  "Hi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أهلًا"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
  "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإدارة"])},
  "Enter Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل وصف"])},
  "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
  "Wait for the redirection..": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتظر التحويل.."])},
  "Film Written Script": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نص الفيلم مكتوباً"])},
  "Click here to download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضغط هنا للتحميل"])},
  "Introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعريف"])},
  "Introduction Video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيديو تعريف بالموسوعة المرئية"])},
  "Guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دليل التخصصات"])},
  "No script available now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيتوفر نص هذه الحلقة لاحقًا"])}
}