<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" md="10">
        <div style="padding:56.25% 0 0 0;position:relative;"><iframe
            src="https://player.vimeo.com/video/1059379454?h=49f537dd33&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
            style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PromotionVideoPage'
}
</script>
